
import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import TahsilatTuruPicker from "@/components/pickers/TahsilatTuruPicker.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import Kur from "@/components/inputs/Kur.vue";
import {TahsilatTuru, TahsilatTuruLabel} from "@/enum/TahsilatTuru";
import TakipBorcluPicker from "@/components/pickers/TakipBorcluPicker.vue";
import TahsilHarciPicker from "@/components/pickers/TahsilHarciPicker.vue";
import AltTahsilatTuruPicker from "@/components/pickers/AltTahsilatTuruPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {TakipEntity} from "@/entity/TakipEntity";
import {HarcTuruEntity} from "@/entity/HarcTuruEntity";
import {AxiosResponse} from "axios";
import {HarcDonemiEntity} from "@/entity/HarcDonemiEntity";
import {AltTahsilatTuru} from "@/enum/AltTahsilatTuru";

@Component({
  components: {
    Kur,
    FormWrapper,
    AltTahsilatTuruPicker,
    TahsilHarciPicker,
    TakipBorcluPicker,
    Tutar,
    TahsilatTuruPicker,
    Dates,
    ParaBirimiPicker
  }
})
export default class TahsilatForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takip!: TakipEntity;
  @Ref('takipBorcluPicker') readonly takipBorcluPicker!: TakipBorcluPicker;

  hideSubmitButton:boolean=false

  TahsilatTuru = TahsilatTuru;
  ParaBirimi = ParaBirimi;
  TakipDurumu = TakipDurumu;
  items: Array<HarcDonemiEntity> = [];
  selectedItem: HarcDonemiEntity = new HarcDonemiEntity();
  altTahsilatTuru = AltTahsilatTuru;
  takipSonrasi: boolean = false;
  tarihKontrol: boolean = false;
  isHesaplaniyor: boolean = false;
  harctanMuafMiOranAlert:boolean = false

  get isHideButtonsSubmit(){
    if (this.takip.takip_durumu==TakipDurumu.acilmamis){
      if (this.tarihKontrol){
        return this.hideSubmitButton = true
      }else{
        return this.hideSubmitButton = false
      }
    }
  }

  @Watch("localValue.id")
  onItemChange() {
    if (!!this.localValue.id) {
      this.localValue.net_tutar = Number(this.value.net_tutar);
    }
  }

  @Watch('takip.borclular')
  onBorcluChange() {
    this.takipBorcluPicker.load();
  }

  @Watch('localValue.tahsilat_turu')
  changeTahsilatTuru(){
    if (this.localValue.tahsilat_turu!==this.TahsilatTuru.icra){
      this.localValue.cezaevi_harci=0
    }
      this.netTutarHesapla()

  }
  load() {
    this.$emit('load');
  }

  birGunCikar() {
    let gun = new Date(this.takip.takip_tarihi).setDate(new Date(this.takip.takip_tarihi).getDate() - 1)
    let myDate = new Date(gun).toISOString().substr(0, 10);
    return myDate
  }

  get filterTurler(){
    if (new Date(this.localValue.tarih).getTime() < new Date(this.takip.takip_tarihi).getTime()){
      const filteredTurler = Object.values(TahsilatTuru)
        .filter(turu => turu !== TahsilatTuru.icra);
      return filteredTurler;
    } else {
      return Object.values(TahsilatTuru);
    }
  }

  get filterTurlerLabel(){
    if (new Date(this.localValue.tarih).getTime() < new Date(this.takip.takip_tarihi).getTime()){
      const filteredLabels = Object.values(TahsilatTuruLabel)
        .filter((label, index) => Object.values(TahsilatTuru)[index] !== TahsilatTuru.icra);
      return filteredLabels;
    } else {
      return Object.values(TahsilatTuruLabel);
    }

  }


  @Watch('localValue.tarih')
  onTahsilatTarihi() {

    if (new Date(this.localValue.tarih).getTime() > new Date(this.takip.takip_tarihi).getTime()) {
      this.tarihKontrol = true;
      this.input();
    } else {
      this.tarihKontrol = false;
    }
    
    // Eğer tarih takip tarihi sonrasıysa otomatik hesapla
    const isTakipSonrasi = new Date(this.localValue.tarih).getTime() >= new Date(this.takip.takip_tarihi).getTime();
    
    if (this.takipSonrasi != isTakipSonrasi) {
      this.takipSonrasi = isTakipSonrasi;
      
      if (this.takipSonrasi) {
        // Tarih takip sonrası oldu - hesapla
        if (this.localValue.tahsil_harci_orani === "0.00" ||
            !this.localValue.tahsil_harci_orani) {
          this.localValue.tahsil_harci_orani = "4.55";
        }
        setTimeout(() => {
          this.manuelHarcHesapla();
        }, 100);
      } else {
        // Tarih takip öncesi oldu - sıfırla
        this.localValue.tahsil_harci = 0;
        this.localValue.tahsil_harci_orani = "0.00";
      }
    }
    
    this.input();
  }

  @Watch('localValue.tutar')
  onTutarChange() {
    console.log("Tutar değişti:", this.localValue.tutar);
    
    // Takip öncesi ise net tutar = tutar
    if (!this.takipSonrasi) {
      this.localValue.net_tutar = Number(this.localValue.tutar);
    } 
    // Takip sonrası ise harç hesapla
    else if (this.takipSonrasi) {
      // Gecikmeli olarak manuel hesaplama yap
      setTimeout(() => {
        this.manuelHarcHesapla();
      }, 100);
    }
  }

  mounted() {
    if (this.takip.takip_durumu != TakipDurumu.acilmamis) {
      this.takipSonrasi = true;
    }
    if (!this.localValue.tutar) {
      this.localValue.net_tutar = 0;
    }
    if (!this.localValue?.id) {
      this.localValue.tarih = new Date();
      this.localValue.giris_tarihi = new Date();
      this.localValue.alt_tahsilat_turu_id = null;
    }
    
    // Düzenleme modunda ve harç 0'sa hesapla
    if (this.localValue?.id && this.takipSonrasi && 
        this.localValue.tutar && 
        (!this.localValue.tahsil_harci || Number(this.localValue.tahsil_harci) <= 0)) {
      // Gecikmeli çalıştır
      setTimeout(() => {
        console.log("Otomatik harç hesaplanıyor...");
        this.manuelHarcHesapla();
      }, 500);
    }
    
    this.getHarcDonemi();
  }

  onKurChange() {
    if (this.takipSonrasi) {
      this.onTahsilHarciOraniChange();
      this.harcHesapla();
    } else
      this.input();
  }

  harcHesapla() {
    if (this.takipSonrasi) {
      if (!this.localValue.tutar)
        this.localValue.net_tutar = 0;
      if (this.localValue.tutar &&
          this.localValue.tahsil_harci &&
          (this.localValue.para_birimi_id == ParaBirimi.TRY || this.localValue.kur) &&
          !this.localValue?.id) {
        if (this.localValue.para_birimi_id == ParaBirimi.TRY) {
          this.localValue.kur = 1;
        }
        if (this.localValue.tahsilat_turu == this.TahsilatTuru.icra) {
          this.netTutarHesapla();
        } else {
          this.localValue.net_tutar = this.localValue.tutar;
        }
      }
      this.input();
    }
  }

  changeTahsilHarcOran(){
    if (this.localValue.tahsil_harci_orani != "0.00" && this.takip.alacaklilar.some(item => item.muvekkil.kurum.harc_muaf_mi)){
      this.harctanMuafMiOranAlert = true;
    } else if(this.localValue.tahsil_harci_orani == "0.00" || this.takip.alacaklilar.some(item => !item.muvekkil.kurum.harc_muaf_mi)){
      this.harctanMuafMiOranAlert = false;
    }
    
    // Oran 0 değilse hesapla
    if (this.localValue.tahsil_harci_orani !== "0.00") {
      setTimeout(() => {
        this.manuelHarcHesapla();
      }, 100);
    }
  }

  async onTahsilHarciOraniChange() {
    this.isHesaplaniyor = true;
    this.localValue.cezaevi_harci = 0;
    if (this.localValue.para_birimi_id == ParaBirimi.TRY)
      this.localValue.kur = 1;
    
    if (this.localValue.tutar) {
      try {
        // API üzerinden harç hesaplama
        let response = await this.$http.post('/api/v1/takip/' + this.takip.id + '/tahsil-harci-hesapla', {
          tahsilat_id: this.localValue.id ?? null,
          tutar: this.localValue.tutar,
          tahsil_harci_orani: this.localValue.tahsil_harci_orani,
          kur: this.localValue.kur
        });
        
        if (typeof response !== "boolean") {
          this.localValue.tahsil_harci = response.data.tahsil_harci_tutari;
        }
        
        // Eğer API'den hesaplanan tahsil harcı 0 ise manuel olarak hesapla
        if (!this.localValue.tahsil_harci || this.localValue.tahsil_harci == 0) {
          const oran = parseFloat(this.localValue.tahsil_harci_orani || 0);
          // Oran 0 değilse hesapla
          if (oran > 0) {
            this.localValue.tahsil_harci = (Number(this.localValue.tutar) * oran / 100) * (this.localValue.kur || 1);
          }
        }
      } catch (error) {
        console.error("Harç hesaplama hatası:", error);
        // API çağrısı başarısız olursa manuel hesapla
        const oran = parseFloat(this.localValue.tahsil_harci_orani || 0);
        if (oran > 0) {
          this.localValue.tahsil_harci = (Number(this.localValue.tutar) * oran / 100) * (this.localValue.kur || 1);
        }
        this.isHesaplaniyor = false;
      }
    }
    
    this.netTutarHesapla();
    this.isHesaplaniyor = false;
    this.input();
  }

  netTutarHesapla() {
    let tutar = this.localValue.tutar ?? 0;
    if (typeof tutar == 'string') tutar = Number(tutar);
    if (this.localValue.para_birimi_id != ParaBirimi.TRY) tutar *= this.localValue.kur;
    
    if (!this.takipSonrasi) {
      this.localValue.net_tutar = tutar;
    } else if (this.localValue.tahsilat_turu == this.TahsilatTuru.icra && this.takipSonrasi) {
      const harcOrani = parseFloat(this.localValue.tahsil_harci_orani || 0);
      
      if (harcOrani == 0) {
        this.localValue.net_tutar = this.localValue.tutar;
        this.localValue.cezaevi_harci = 0;
        this.localValue.tahsil_harci = 0;
      } else {
        // Tahsil harcı hesaplanmamışsa hesapla
        if (!this.localValue.tahsil_harci || this.localValue.tahsil_harci == 0) {
          this.localValue.tahsil_harci = (tutar * harcOrani / 100);
        }
        
        // Cezaevi harcını hesapla
        this.localValue.cezaevi_harci = tutar * 0.02;
        
        // Net tutarı hesapla
        this.localValue.net_tutar = tutar - (Number(this.localValue.tahsil_harci) + Number(this.localValue.cezaevi_harci));
      }
      
      if (this.localValue.para_birimi_id != ParaBirimi.TRY)
        this.localValue.net_tutar /= this.localValue.kur;
    }
    else {
      // Tahsil harcı hesaplanmamışsa hesapla
      const harcOrani = parseFloat(this.localValue.tahsil_harci_orani || 0);
      if (harcOrani > 0 && (!this.localValue.tahsil_harci || this.localValue.tahsil_harci == 0)) {
        this.localValue.tahsil_harci = (tutar * harcOrani / 100);
      }
      
      this.localValue.net_tutar = tutar - Number(this.localValue.tahsil_harci);
      if (this.localValue.para_birimi_id != ParaBirimi.TRY)
        this.localValue.net_tutar /= this.localValue.kur;
    }
    
    if (isNaN(this.localValue.net_tutar)){
      this.localValue.net_tutar = 0;
    }
  }

  getHarcDonemi() {
    this.$http.get("/api/v1/tahsilat-harclar").then((response) => {
      this.items = response.data;
    });
  }

  get tahsilHarciOranlari(){
    let tahsilHarc:any=[]
    this.items.forEach((item: any) => {
      if(item.oran==2.275){
        tahsilHarc.push(Number(item.oran).toFixed(3).toString());
      }else {
        tahsilHarc.push(Number(item.oran).toFixed(2).toString());
      }
      tahsilHarc.push("0.00")
    })
    if (this.takip.alacaklilar.some(item => item.muvekkil.kurum) && this.takip.alacaklilar.some(item => item.muvekkil.kurum.harc_muaf_mi)){
      this.localValue.tahsil_harci_orani = "0.00";
    }
    return tahsilHarc
  }

  /*get tahsilHarciOranlari() {
    let harclar !: HarcTuruEntity[];
    harclar = this.selectedItem.harclar;
    if (!harclar) {
      return null;
    }
    let harcTurleri = harclar.filter((x: any) => x.harc_turu.harc_tipi === "tahsil")
    let harcOranlari: any = [];
    harcTurleri.forEach((harcTuru) => {
      if (harcTuru.oran==2.275){
        harcOranlari.push(harcTuru.oran)
      }else{
        harcOranlari.push(Number(harcTuru.oran).toFixed(2));
      }
      harcOranlari.push("0.00");
      harcOranlari.push("0.90");
      harcOranlari.push("1.80");
      harcOranlari.push("1.98");
      harcOranlari.push("2.27");
      harcOranlari.push("3.60");
      harcOranlari.push("3.96");
      harcOranlari.push("5.00");
      harcOranlari.push("7.20");
      harcOranlari.push("7.92");
      harcOranlari.push("9.00");
      harcOranlari.push("9.90");
    });
    return harcOranlari;
  }*/

  save() {
    if (this.localValue.id) {
      this.$http.put("/api/v1/tahsilat/" + this.localValue.id, this.localValue).then(() => {
        this.$emit('onSuccess');
      })
    } else {
      this.$http.post("/api/v1/takip/" + this.takip?.id + "/tahsilat/", this.localValue).then(() => {
        this.$emit('onSuccess');
      })
    }
  }

  // Elle hesaplama yapılacak
  manuelHarcHesapla() {
    // Takip sonrası değilse veya tutar yoksa işlem yapma
    if (!this.takipSonrasi || !this.localValue.tutar) return;
    
    // Oran 0 ise hesaplama
    if (this.localValue.tahsil_harci_orani === "0.00") return;
    
    // Harç hesapla
    const tutar = Number(this.localValue.tutar);
    const oran = Number(this.localValue.tahsil_harci_orani.replace(',','.'));
    const kur = this.localValue.kur ? Number(this.localValue.kur) : 1;
    
    // Hesapla ve ata
    const hesaplananHarc = (tutar * kur * oran) / 100;
    
    if (!this.localValue.tahsil_harci || Number(this.localValue.tahsil_harci) <= 0) {
      this.localValue.tahsil_harci = hesaplananHarc;
    }
    
    // Cezaevi harcı da hesapla
    if (this.localValue.tahsilat_turu == this.TahsilatTuru.icra) {
      this.localValue.cezaevi_harci = tutar * kur * 0.02;
    }
    
    // Net tutarı hesapla
    this.netTutarHesapla();
  }
}
